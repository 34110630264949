<template>
  <!-- eslint-disable vue/no-v-html -->
  <div
    class="markdown_viewer"
    :class="{ small_headline: smallHeadline }"
    v-html="mdParsed"
  />
  <!-- eslint-enable -->
</template>

<script>
import marked from "marked";
import DOMPurify from "dompurify";

export default {
  name: "MarkdownViewer",
  props: {
    markdown: {
      type: String,
      default: null,
    },
    smallHeadline: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    mdParsed: null,
  }),
  mounted() {
    if (this.markdown) {
      const renderer = new marked.Renderer();

      renderer.link = (href, title, text) => {
        return `<a href="${href}" target="_blank" title="${title}">${text}</a>`;
      };

      this.mdParsed = DOMPurify.sanitize(
        marked(this.markdown, {
          breaks: true,
          gfm: true,
          renderer: renderer,
        }),
        {
          ADD_ATTR: ["target"],
        }
      );
    }
  },
};
</script>

<style scoped lang="scss">
%_markdown_sect_heading_common_settings {
  margin-top: 1.5em;
  margin-bottom: 1em;

  &:first-child {
    margin-top: 0;
  }
}

%_markdown_sect_default {
  h1 {
    @extend %_markdown_sect_heading_common_settings;
    font-weight: bold;
    font-size: 170%;
    padding: 5px 0;
    border-bottom: 2px solid #b0bec5;
  }

  h2 {
    @extend %_markdown_sect_heading_common_settings;
    font-weight: bold;
    font-size: 150%;
    padding: 5px 0;
    border-bottom: 1px solid #b0bec5;
  }

  h3 {
    @extend %_markdown_sect_heading_common_settings;
    font-weight: bold;
    font-size: 140%;
  }

  h4 {
    @extend %_markdown_sect_heading_common_settings;
    font-weight: bold;
    font-size: 120%;
  }

  h5 {
    @extend %_markdown_sect_heading_common_settings;
    font-size: 120%;
  }

  h6 {
    @extend %_markdown_sect_heading_common_settings;
    font-size: 100%;
  }

  hr {
    border: none;
    border-bottom: 1px solid #b0bec5;
  }

  ul,
  ol {
    margin: 1.5em 0;
    padding-left: 2.5em;

    li {
      padding: initial;
    }
  }

  ul li {
    list-style: initial;
    margin-bottom: 1.5rem;
  }

  table {
    min-width: 50%;
    margin: 1em 0;
    border: 1px solid #b0bec5;

    th,
    td {
      padding: 5px;
      border: 1px solid #b0bec5;
    }

    th {
      background-color: #edf3f4;
    }
  }

  p {
    line-height: 2.4rem;
  }

  blockquote {
    border-left: 3px solid #b0bec5;
    margin: 2em 1.5em;
    padding: 0.5em 1em;
  }
}

.markdown_viewer::v-deep {
  @extend %_markdown_sect_default;
}

.markdown_viewer.small_headline::v-deep {
  @extend %_markdown_sect_default;

  h1 {
    font-size: 150%;
  }

  h2 {
    font-size: 135%;
  }

  h3 {
    font-size: 135%;
  }

  h4 {
    font-size: 115%;
  }

  h5 {
    font-size: 115%;
  }

  h6 {
    font-size: 100%;
  }
}
</style>
