<template>
  <div>
    <!-- <p>{{ document }}</p> -->
    <v-container fluid class="mx-auto">
      <v-list max-width="1200" class="mx-auto">
        <v-list-item three-line class="pb-0">
          <v-list-item-avatar color="primary" size="48" class="my-0">
            v
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title style="font-size: 14px;" class="font-weight-bold mb-2">
              {{ getTextWithLocale(base.title) }}
            </v-list-item-title>
            <v-list-item-subtitle v-if="vocabulary['dct:publisher']" class="mb-3">
              {{ vocabulary["dct:publisher"] }}
            </v-list-item-subtitle>
            <v-container v-if="base.categories.length" class="pa-0 mb-6">
              <v-btn
                v-for="(genre, i) in base.categories"
                :key="i"
                depressed
                small
                class="mr-2"
              >
                {{ genre }}
              </v-btn>
            </v-container>
            <v-container class="pa-0">
              {{ getTextWithLocale(base.description) }}
            </v-container>
            <p class="caption mb-1">
              {{ getWords("name_meta_published") }}
              <v-btn text color="purple darken-1" class="caption pa-0">
                {{ base.published }}
              </v-btn>
            </p>

            <v-container class="pa-0">
              <v-btn
                v-for="(h, i) in base.tags"
                :key="i"
                depressed
                small
                color="#8F5F98"
                class="white--text mr-2"
              >
                #{{ h }}
              </v-btn>
            </v-container>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-container>
        <v-list class="mx-auto">
          <v-subheader>
            {{ getWords("vocabulary_view_distribution") }}
          </v-subheader>
          <div v-for="(v, i) in distributions" :key="i">
            <v-divider v-if="i !== 0"></v-divider>
            <v-list-item>
              <v-list-item-icon>
                <v-icon color="blue-grey lighten-1">mdi-attachment</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ v.title }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-action>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="downloadBtnPressed(i)">
                      <v-list-item-icon>
                        <v-icon>mdi-download</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>DOWNLOAD</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item v-if="hasDistMetaCSV(v)" @click="metaDownloadBtnPressed(i, 'dist')">
                      <v-list-item-icon>
                        <v-icon>mdi-download</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ getWords("vocabulary_view_dist_org_csv") }}
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </div>
        </v-list>
      </v-container>
    </v-container>

    <v-container class="pa-0">
      <v-tabs v-model="tab" centered class="pa-0" color="black">
        <v-tab
          v-for="(m, i) in menus"
          :key="i"
          :href="'#' + m"
          @click="handleChangeTab(m)"
        >
          {{ m }}
        </v-tab>
        <v-tabs-slider color="#8F5F98"></v-tabs-slider>
      </v-tabs>
    </v-container>
    <v-divider class="mb-9"></v-divider>

    <v-container>
      <v-list v-if="selectTab === 'STRUCTURE'" max-width="1200" class="mx-auto">
        <div v-if="distJson.type === 'LOADING'" align="center">
          <v-progress-circular
            class="ma-4"
            indeterminate
            color="dark-grey"
          ></v-progress-circular>
        </div>
        <div v-if="distJson.type === 'Unavailable'" class="text-center">
          <p class="caption">{{ getWords("vocabulary_view_message1") }}</p>
        </div>
        <div v-if="distJson.type === 'SKOS'">
          <v-list-group v-for="(itemSet, key) in distJson.body" :key="key">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="text-h5">
                  - {{ key }} ({{ itemSet.length }})
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <v-list-group v-for="item in itemSet" :key="item['uri']" sub-group>
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="text-h6">
                    - {{ getTitleLabel(item["skos:prefLabel"]) }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item>URI: {{ item["uri"] }}</v-list-item>
              <v-list-item v-for="(value, v) in item['skos:prefLabel']" :key="v+value">
                {{ getWords("vocabulary_view_skos1") }}: {{ value }}
              </v-list-item>
              <v-list-item v-for="(value, v) in item['skos:altLabel']" :key="v+value">
                {{ getWords("vocabulary_view_skos2") }}: {{ value }}
              </v-list-item>
              <v-list-item v-for="(value, v) in item['skos:description']" :key="v + value">
                {{ getWords("vocabulary_view_skos3") }}: {{ value }}
              </v-list-item>
              <v-list-item v-for="(value, v) in item['skos:broadMatch']" :key="v + value">
                {{ getWords("vocabulary_view_skos4") }}: {{ value }}
              </v-list-item>
              <v-list-item v-for="(value, v) in item['skos:exactMatch']" :key="v + value">
                {{ getWords("vocabulary_view_skos5") }}: {{ value }}
              </v-list-item>
            </v-list-group>
          </v-list-group>
        </div>
        <div v-else>
          <v-list-group v-for="(itemSet, key) in distJson.body" :key="key">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title class="text-h5">
                  - {{ key }} ({{ itemSet.length }})
                </v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-group
              v-for="item in itemSet"
              :key="item['uri']"
              sub-group
              :group="item['uri']"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title class="text-subtitle-1">
                    - {{ getTitleLabel(item["rdfs:label"]) }}
                  </v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item>
                {{ item["uri"] }}
              </v-list-item>
              <v-list-item v-for="value in item['rdfs:label']" :key="value">
                {{ value }}
              </v-list-item>
              <v-list-item
                v-for="subkey of getExistSubKeyList(item)"
                :key="subkey"
              >
                <v-list-group
                  v-for="subItem in item[subkey]"
                  :key="'sub_' + subItem['uri']"
                  sub-group
                  :group="subItem['uri']"
                >
                  <template v-slot:activator>
                    <v-list-item-content>
                      <v-list-item-title class="text-subtitle-1">
                        - {{ getTitleLabel(subItem["rdfs:label"]) }}
                      </v-list-item-title>
                      <v-list-item-subtitle>
                        - {{ subkey }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                  <v-list-item>
                    {{ subItem["uri"] }}
                  </v-list-item>
                  <v-list-item
                    v-for="value in subItem['rdfs:label']"
                    :key="value"
                  >
                    {{ value }}
                  </v-list-item>
                </v-list-group>
              </v-list-item>
            </v-list-group>
          </v-list-group>
        </div>
      </v-list>

      <v-container v-if="selectTab === 'DOCS'">
        <markdown-viewer :markdown="vocabulary.documents" />
      </v-container>

      <v-list v-if="selectTab === 'META'" max-width="1200" class="mx-auto">
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ getWords("name_meta_created") }}
            </v-list-item-title>
            <p class="caption">
              {{ getDate(base.created) }}
            </p>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ getWords("name_meta_modified") }}
            </v-list-item-title>
            <p class="caption">
              {{ getDate(base.updated) }}
            </p>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ getWords("name_meta_contact") }}
            </v-list-item-title>
            <p class="caption">
              {{ contacts.email }}
            </p>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              {{ getWords("name_meta_license") }}
            </v-list-item-title>
            <p class="caption">{{ base.license }}</p>
          </v-list-item-content>
        </v-list-item>

        <div v-if="hasMetaCsv">
          <v-divider></v-divider>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="blue-grey lighten-1">mdi-attachment</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>
                {{ getWords("vocabulary_view_meta_csv") }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="metaDownloadBtnPressed(0, 'meta')">
                    <v-list-item-icon>
                      <v-icon>mdi-download</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title>DOWNLOAD</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </div>
      </v-list>
    </v-container>
  </div>
</template>

<script>
// import { db } from "@/plugins/firebase";
import moment from "moment";
import MarkdownViewer from "../components/MarkdownViewer.vue";
import {
  getTargetVocabulary,
  getParsedDistribution,
  getDistributionFile,
} from "../plugins/firebase";
import VocabUtil from "../plugins/vocabulary_utils";
import words from "../assets/localewords.json";

export default {
  name: "ResourcesDetail",

  components: {
    MarkdownViewer,
  },

  data() {
    return {
      vocabulary: {},
      base: VocabUtil.initMeta(),
      contacts: VocabUtil.initContacts(),
      app_metadata: VocabUtil.initAppMeta(),
      distributions: [],
      distJson: { type: "LOADING", body: [] },
      // dataList: [],
      // menus: ["STRUCTURE", "DOCS", "META"],
      menus: ["META", "STRUCTURE"],
      // hashList: ["物流", "農業", "医療", "気象"],
      // catalogs: ["title", "description", "mbox", "address"],
      selectTab: "META",
      subKeys: ["rdfs:subClassOf", "rdfs:range", "rdfs:domain"],
      tab: null,
    };
  },

  // mounted() {
  //   console.log("mounted");
  // },

  async created() {
    const targetVocab = await getTargetVocabulary(this.$route.params.id);
    const parseData = VocabUtil.convertVocabularyToObject(targetVocab);
    this.base = parseData.base;
    this.contacts = parseData.contacts;
    this.app_metadata = parseData.app_meta;
    this.distributions = parseData.distributions;
    const available = this.distributions.filter((item) => {
      return item.downloadURL && item.downloadURL.includes("http");
    });
    if (available.length > 0) {
      this.downloadJSON(available[0].metadata.id);
    } else {
      this.distJson.type = "Unavailable";
    }
  },

  computed: {
    getLanguage() {
      return this.$store.state.language;
    },
    hasMetaCsv() {
      if (this.app_metadata.specializedObject && this.app_metadata.specializedObject.meta_csv_url) {
        return true;
      }
      return false;
    },
  },

  // watch: {
  //   vocabulary() {
  //     console.log("watch vocab");
  //     this.refreshedVocabulary();
  //   },
  // },

  methods: {
    hasDistMetaCSV: function (dist) {
      if (dist.metadata.specializedObject && dist.metadata.specializedObject.vocabulary_csv_url) {
        return true;
      }
      return false;
    },
    async downloadJSON(distID) {
      try {
        this.distJson = await getParsedDistribution(
          this.$route.params.id,
          distID
        );
        // TODO: 後で消すこと
        // this.createSearchWords(this.distJson);
        // const url = 'http://localhost:5001/vocs-development/asia-northeast1/vocabularies/' + this.$route.params.id + '/keywords?update=true'
        // const result = await fetch(url, {
        //   method: "GET",
        //   mode: "cors",
        //   cache: "no-cache",
        // });
        // if (result.ok) {
        //   const a = await result.json();
        //   console.log(a)
        // }
      } catch (err) {
        console.log(err);
        this.distJson.type = "Unavailable";
      }
    },

    async handleChangeTab(target) {
      this.selectTab = target;
    },

    getWords(key) {
      let lang = this.getLanguage;
      if (lang !== "en" && lang !== "ja") {
        lang = "en";
      }
      const word = words[key] ? words[key][lang] : "";
      return word;
    },

    getDate(fDate) {
      try {
        const date = moment(fDate.toDate()).format("YYYY/MM/DD");
        return date;
      } catch (e) {
        console.log(e.message);
      }
      return "";
    },

    getExistSubKeyList(item) {
      const keys = this.subKeys.filter((subkey) => {
        return item[subkey];
      });
      return keys;
    },

    getTitleLabel(array) {
      const langCode = "@" + this.getLanguage;
      const target = array.find((item) => item.split(langCode).length > 1);
      if (target === undefined) {
        return array[0];
      } else {
        return target.split(langCode)[0];
      }
    },

    getTextWithLocale(text) {
      // console.log(text);
      if (text === undefined || text === null) {
        return "";
      }
      if (this.getLanguage === "ja") {
        if (text.ja !== "") {
          return text.ja;
        } else {
          return text.en;
        }
      } else {
        if (text.en !== "") {
          return text.en;
        } else {
          return text.ja;
        }
      }
    },
    async downloadBtnPressed(index) {
      const dist = this.distributions[index];
      const text = await getDistributionFile(
        this.$route.params.id,
        dist.metadata.id
      );
      const blob = new Blob([text], {
        type: "text/plain",
      });
      const fileName = VocabUtil.createFileNameFromUrl(dist.downloadURL);
      const link = document.createElement("a");
      if (window.navigator.msSaveOrOpenBlob) {
        /* msSaveOrOpenBlobの場合はファイルを保存せずに開ける */
        window.navigator.msSaveOrOpenBlob(blob, fileName);
      } else if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, fileName);
      } else {
        const url = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.href = url;
        link.click();
        window.URL.revokeObjectURL(url);
        link.remove();
      }
    },
    metaDownloadBtnPressed: async function (index, type) {
      // console.log(this.exports.selected);
      let url = null;
      if (type === "meta") {
        url = this.app_metadata.specializedObject.meta_csv_url;
      } else if (type === "dist") {
        const dist = this.distributions[index];
        url = dist.metadata.specializedObject.vocabulary_csv_url;
      }
      if (!url) {
        return;
      }
      const fileName = VocabUtil.createFileNameFromUrl(url);
      const link = document.createElement("a");
      link.href = url;
      link.download = fileName;
      link.click();
      link.remove();
    },

    parseLangageParam(text) {
      const a = text.split("@");
      return a.length > 0 ? a[0] : text;
    },
  },

  // firestore() {
  //   return {
  //     vocabulary: db.collection("vocabularies").doc(this.$route.params.id),
  //   };
  // },
};
</script>

<style scoped></style>
